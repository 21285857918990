import React from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import MaterialUILayout from '../components/material-ui-layout';
import { GatsbyImage } from 'gatsby-plugin-image';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import GilaRiverData from '../components/data/gila-river-data';
import GranadaData from '../components/data/granada-data';
import HeartMountainData from '../components/data/heart-mountain-data';
import JeromeData from '../components/data/jerome-data';
import ManzanarData from '../components/data/manzanar-data';
import MinidokaData from '../components/data/minidoka-data';
import PostonData from '../components/data/poston-data';
import RohwerData from '../components/data/rohwer-data';
import TopazData from '../components/data/topaz-data';
import TuleLakeData from '../components/data/tule-lake-data';

import BigDCampgroundData from '../components/data/big-d-campground-data';
import FieldsBrookStreamData from '../components/data/fields-brook-stream-data';
import FeedMaterialsProductionCenterData from '../components/data/feed-materials-production-center-data';
import DOEIndianMoundData from '../components/data/doe-indian-mound-data';
import IndustrialExcessLandfillData from '../components/data/industrial-excess-landfill-data';
import LaskinPoplarData from '../components/data/laskin-poplar-data';
import LoveCanalData from '../components/data/love-canal-data';
import NeaseChemicalData from '../components/data/nease-chemical-data';
import Site666Data from '../components/data/site-666-data';
import SummitNationalData from '../components/data/summit-national-data';

const shortcodes = {
  GilaRiverData,
  GranadaData,
  HeartMountainData,
  JeromeData,
  ManzanarData,
  MinidokaData,
  PostonData,
  RohwerData,
  TopazData,
  TuleLakeData,

  BigDCampgroundData,
  FieldsBrookStreamData,
  FeedMaterialsProductionCenterData,
  DOEIndianMoundData,
  IndustrialExcessLandfillData,
  LaskinPoplarData,
  LoveCanalData,
  NeaseChemicalData,
  Site666Data,
  SummitNationalData,
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        name
        title
        series
        media
        size
        year
        city
        state
        country
        inventory
      }
      body
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: 'italic',
    
    [theme.breakpoints.down('sm')]: {
      fontSize: '.9rem',
      margin: '1rem 0 0 1rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '1rem',
      margin: '1rem 0 0 1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1.5rem',
    },
  },

  image: {
    maxWidth: '95vw',
    margin: '4rem auto',


  },
  artist: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
      margin: '1rem 0 0 1rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.8rem',
      margin: '1rem 0 0 1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.9rem',

    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },

  },
  
  media: {
    margin: '0 0 0 1rem',

    [theme.breakpoints.down('sm')]: {
      fontSize: '.7rem',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '1rem',
    },
  },
  metaData: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {},
    [theme.breakpoints.up('sm')]: {
      marginLeft: '1rem',
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      marginLeft: '2rem',
    },
    
  }

}));

const ArtPageTemplate = ({ data: { mdx: art } }) => {
  let title = art.frontmatter.title;
  let isSacredArchitectures =
    art.frontmatter.series === 'Sacred Architectures' ? true : false;
  let country = art.frontmatter.country;
  const classes = useStyles();

  return (
    <MaterialUILayout>

      <GatsbyImage
        image={art.frontmatter.image.childImageSharp.gatsbyImageData}
        className={classes.image}
        alt={art.frontmatter.title}
        objectFit="contain"
      />

      <div className={classes.metaData}>
        <Typography variant="h3"
          className={classes.title}
        >
            {title}, {art.frontmatter.year}
        </Typography>

        <Typography color="primary" variant="body2"
          className={classes.artist}
        >
          by Dr. Masumi Hayashi
        </Typography>

        <Typography variant="body1"
          className={classes.media}
        >
          {art.frontmatter.media},&nbsp;
          {art.frontmatter.size}
        </Typography>

        <p
          css={css`
            font-size: 1rem;
          `}
        >
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{art.body}</MDXRenderer>
          </MDXProvider>
        </p>
      </div>
    </MaterialUILayout>
  );
};

export default ArtPageTemplate;
